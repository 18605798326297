import Cropper from 'cropperjs'
(function($) {
    // https://fengyuanchen.github.io/cropperjs/examples/upload-cropped-image-to-server.html

    var image = $("#cropper-image")[0];
    var input = $("#main-photo-upload");
    var horAvatar = $("#main-photo-preview-horizontal");
    var varAvatar = $("#main-photo-preview-vertical");
    var cropper;
    var $modal = $('#cropper-modal');

    $('#crop-current-photo-button').on('click', function(e) {
        var uncropped_url = $("#existing-photo-file-field").attr('data-value');
        image.src = uncropped_url;
        $modal.modal('show');
    })

    input.on('change', function (e) {
        var files = $("#main-photo-upload").prop("files");
        //TODO: make this work. Problems loading the existing file is
        //preventing cropping the existing profile picture
        var done = function (url) {
            input.value = '';
            image.src = url;
            // $alert.hide();
            $modal.modal('show');
        };
        var reader;
        var file;
        var url;

        if (files && files.length > 0) {
            file = files[0];
            if (file.size > 5242879) {
              alert("File size is too large. Must be less than 5MB")
            }else {
              if (URL) {
                  done(URL.createObjectURL(file));
              } else if (FileReader) {
                  reader = new FileReader();
                  reader.onload = function (e) {
                  done(reader.result);
                  };
                  reader.readAsDataURL(file);
              }
            }
        }
    });

    $("#crop-current-photo-button").on("click", function(){
        var files = $("#main-photo-upload").files;
        var done = function (url) {
            input.value = '';
            image.src = url;
            // $alert.hide();
            $modal.modal('show');
        };
        var reader;
        var file;
        var url;

        if (files && files.length > 0) {
            file = files[0];
            if (URL) {
                done(URL.createObjectURL(file));
            } else if (FileReader) {
                reader = new FileReader();
                reader.onload = function (e) {
                done(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    });

    $modal.on('shown.bs.modal', function () {

        // define aspect ratio by profile layout choice
        var layoutSelector = $("#horizontal-profile-picture-field").val() == 'true' ?
            ".artist__jumbotron" :
            ".vertical-artist__left";
            if ($("#type-profile-picture-field").val() == 'Manager') {
              layoutSelector = ".non-performer__preview"
            }

        var aspectRatio = $(layoutSelector).width() / $(layoutSelector).height();
        var aspectRatio = $("#horizontal-profile-picture-field").val() == 'true' ?
            aspectRatio : 40/47
        cropper = new Cropper(image, {
            viewMode: 1,
            scalable: false,
            aspectRatio: isNaN(aspectRatio) ? 1 : aspectRatio ,
        });
    }).on('hidden.bs.modal', function () {
        cropper.destroy();
        cropper = null;
    });

    $('#crop').on('click', function () {
        let cropper_data = cropper.getData(true)
        // console.log(cropper_data)
        $("#hidden-field-pic_width").val(cropper_data.width);
        $("#hidden-field-pic_height").val(cropper_data.height);
        $("#hidden-field-pic_x").val(cropper_data.x);
        $("#hidden-field-pic_y").val(cropper_data.y);

        $("#profile-pic-submit-button").click()
    });

})(jQuery);
